import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { isEventEnded } from '@aurora/shared-client/helpers/forms/DateHelper/DateHelper';
import { canSendOccasionInvites } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { isNodeOccasion } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import type {
  OccasionTopicMessage,
  TopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

const OccasionInviteModal = dynamic(
  () => import('../../occasions/OccasionInviteModal/OccasionInviteModal'),
  {
    ssr: false
  }
);

/**
 * The message action to send invites to users for an occasion.
 * @param message - the message
 *
 * @author Vishnu
 */
const MessageActionInviteUsers: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();
  const [showOccasionInviteModal, setShowOccasionInviteModal] = useState<boolean>(false);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_INVITE_USERS
  );
  const { endTime } = (message as OccasionTopicMessage)?.occasionData || {};
  const eventEndTime = new Date(endTime);
  const isOccasionEnded = isEventEnded(eventEndTime);

  const { data: occasionNodePolicies, loading: occasionNodePoliciesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanSendOccasionInvites: true
    },
    !message || IdConverter.isOptimistic(tenant, message?.board.id),
    false
  );

  if (!isNodeOccasion(message.board) || isAnonymous || textLoading || occasionNodePoliciesLoading) {
    return null;
  }

  const canInviteUsers: boolean = canSendOccasionInvites(occasionNodePolicies?.coreNode);
  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;

  /**
   * Renders update status modal
   */
  const renderInviteModal = (): React.ReactElement => {
    return (
      <OccasionInviteModal
        show={showOccasionInviteModal}
        onHide={() => setShowOccasionInviteModal(false)}
        occasionMessage={message as OccasionTopicMessage}
      />
    );
  };

  return (
    message.board.conversationStyle === ConversationStyle.Occasion &&
    canInviteUsers &&
    !isOccasionEnded &&
    !isArchived && (
      <>
        <Dropdown.Item
          onClick={(): void => setShowOccasionInviteModal(true)}
          data-testid="InviteUsersOption"
        >
          {formatMessage('title')}
        </Dropdown.Item>
        {showOccasionInviteModal && renderInviteModal()}
      </>
    )
  );
};

export default MessageActionInviteUsers;
